<template>
  <section>
    <div class="content-header">
      <h2>Introducing controversial topics</h2>
    </div>
    <div class="content-wrapper">
      <p>How do you frame a discussion so that it doesn’t devolve into a harmful exchange? Here are some tips:</p>
      <ul>
        <li class="pb-2">Communicate the learning objectives or opportunities associated (for example, the inclusion of a range of perspectives, which would serve to deepen learning of a particular topic).</li>
        <li class="pb-2">Encourage students to listen to understand. Acknowledge that students may have a great deal that they wish to say but that crafting their replies before they have meaningfully listened to and understood a point is detrimental to debate.</li>
        <li class="pb-2">Encourage students to seek clarification or further explanation (for example, “Can you tell me more…”); recognizing that communication styles are not uniform, and words can hold different meanings. </li>
        <li class="pb-2">Debrief at the end of classes by asking students about what different ideas they have learned from the discussion.</li>
        <li>Follow up with an email to the entire class with resources and supports. </li>
      </ul>
      <p>The University of British Columbia also provides a rich resource on establishing conditions for <a href="https://wellbeing.ubc.ca/respectful-dialogue-and-debate" target="_blank">Respectful Dialogue and Debate</a>. </p>

    </div>
  </section>
</template>

<script>
// import Accordion from '@/components/Accordion.vue'
// import SidebarMenu from '@/components/SidebarMenu.vue'
// import {
//   Hooper,
//   Slide,
//   Navigation as HooperNavigation,
//   Pagination as HooperPagination
// } from 'hooper'
// import 'hooper/dist/hooper.css'

export default {
  name: 'content',
  components: {
    // SidebarMenu,
    // Accordion,
    // Hooper,
    // Slide,
    // HooperNavigation,
    // HooperPagination
  },
  data () {
    return {
      publicPath: process.env.BASE_URL
    }
  },

  mounted () {
  }
}
</script>

<style>
</style>
